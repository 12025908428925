import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import "./pageStyles/about-arc.scss"

const AboutPage = () => {
  return (
    <Layout>
      <Seo title="About ARC" />
      <div id="main-content">
        <article id="post-254" className="post-254 page type-page status-publish hentry">
          <div className="entry-content">
            <div className="about-l et-l about-l--post et-l--post">
              <div className="about_builder_inner_content et_builder_inner_content about_pb_gutters3 et_pb_gutters3">
                <div className="about_pb_section et_pb_section about_pb_section_7 et_pb_section_7 about_pb_with_background et_pb_with_background about_section_regular et_section_regular section_has_divider about_pb_bottom_divider et_pb_bottom_divider about_pb_top_divider et_pb_top_divider optml-bg-lazyloaded about_pb_section_first et_pb_section_first" data-fix-page-container="on" style={{ paddingTop: "168px" }} >
                  <div className="about_pb_row et_pb_row about_pb_row_8 et_pb_row_8 optml-bg-lazyloaded">
                    <div className="about_pb_column et_pb_column about_pb_column_4_4 et_pb_column_4_4 about_pb_column_11 et_pb_column_11  about_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough about-last-child et-last-child">
                      <div className="about_pb_module et_pb_module about_pb_text et_pb_text about_pb_text_8 et_pb_text_8 about_pb_text_align_center et_pb_text_align_center about_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                        <div className="about_pb_text_inner et_pb_text_inner">About ARC</div>
                      </div>
                    </div>
                  </div>
                  <div className="about_pb_bottom_inside_divider et_pb_bottom_inside_divider"></div>
                </div> <div className="about_pb_section et_pb_section about_pb_section_8 et_pb_section_8 about_section_regular et_section_regular">
                  <div className="about_pb_row et_pb_row about_pb_row_9 et_pb_row_9 optml-bg-lazyloaded">
                    <div className="about_pb_column et_pb_column about_pb_column_4_4 et_pb_column_4_4 about_pb_column_12 et_pb_column_12  about_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough about-last-child et-last-child">
                      <div className="about_pb_module et_pb_module about_pb_text et_pb_text about_pb_text_9 et_pb_text_9 about_pb_text_align_left et_pb_text_align_left about_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                        <div className="about_pb_text_inner et_pb_text_inner"><p>ARC Medical, Inc. was founded in 1990 by Hal Norris and the company is committed to providing the anesthesia, ICU, Long Term Acute Care and Emergency areas of hospitals and Same Day Surgery Centers with safe and effective products with emphasis on quality. Outside the hospital arena, we provide excellent products for patients in Home Care.</p>
                          <p>The initial product introduced is the highest performing Hygroscopic Condensing Humidifier (HME), the ThermoFlo&trade; System, available anywhere in the world. The introduction of these devices for the ICU and LTAC usage revolutionized the delivery of humidification to the ventilator dependent patient. Our ThermoFlo&trade; Trach products provide excellent humidification to the patients in Home Care. Trach products are available with a suction / Cough Relief Valve as well as supplemental O2 port.</p>
                          <p>In 2009 circuitGuard&reg; was cleared by the FDA under 510(k) K090738. The indications for use (IFU) allowed for the multiple patient use of the anesthesia breathing circuit, a protocol used in Western Europe since the 1980s. ARC has successfully converted facilities to this protocol and those facilities have passed inspection by all of the accreditation agencies. </p></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </Layout>
  )
}

export default AboutPage
